<template>
  <div>
<!--    //搜索-->
    <date-search
    :shopList="shopList"
    @searchImportRecords="searchImportRecords"
    />
<!--    导入记录-->
    <import-records-list
    :importRecordsList="importRecordsList"
    />
<!--    分页-->
    <paging
    :meta="meta"
    @currentPage="currentPage"
    />
  </div>
</template>

<script>
import DateSearch from "@/views/order/importRecords/components/dateSearch";
import ImportRecordsList from "@/views/order/importRecords/components/importRecordsList";
import {importLogList} from "@/api/order";
import {timestamp} from "@/utils/timejs";
import Paging from "@/components/publicTab/index";
import {shopSelect} from "@/api/productTemplate";



export default {
  name: "index.vue",
  components: {Paging, ImportRecordsList, DateSearch},
  data: () => ({
    page:1,
    perPage:10,
    importRecordsList:[],//导入记录list
    meta:{} ,//分页
    shopList:[],//店铺列表
    shop:'',//店铺
  }),
  mounted() {
    // 导入记录
      this.importLogList()
    // 店铺
      this.shopSelect()
  },
  methods: {
    //导入记录
    async importLogList(){
      try {
        const data = await importLogList({
          page:this.page,
          perPage:this.perPage,
          storeCode:this.shop,
          startTime:this.startTime,
          endTime:this.endTime
        })
        this.importRecordsList = data?.data?.list
        this.meta = data?.data?.meta
      }catch (e) {
        const {msg} = e
        this.$message.error(msg)
      }
    },
    // 分页
    currentPage(e){
      this.page = e
      this.importLogList()
    },
    // 店铺
    async shopSelect(){
      try {
        const data = await shopSelect()
        this.shopList = data?.data
        this.shopList.unshift({name:'全部',code:''})

      }catch (e) {
        const {msg} = e
        this.$message.error(msg)
      }
    },
    // 搜索
    searchImportRecords(e){
      this.page = 1
      this.perPage = 10
      this.shop = e.shop
      this.startTime = timestamp(e.time[0])
      this.endTime = timestamp(e.time[1])
      this.importLogList()
    }
  },
  watch: {},

}
</script>

<style scoped lang="less">

</style>