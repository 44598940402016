<template>
  <div>
<!--    <div class="storeName">-->
<!--      <span>店铺名称</span>-->
<!--      <span class="all">全部</span>-->
<!--      <span class="shop" v-for="item in shopList" :key="item.id">{{item.name}}</span>-->
<!--    </div>-->
    <el-form>
      <el-row :gutter="30">
        <el-col :xs="24" :sm="12" :md="12" :lg="5" :xl="5">
          <el-form-item label="店铺">
            <el-select v-model="shop" placeholder="请选择活动区域" size="mini" clearable>
              <el-option v-for="item in shopList" :key="item.id" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="5" :xl="5">
          <el-form-item label="导入账号">
            <el-select v-model="importAccount" placeholder="请选择导入账号" size="mini" clearable>
              <el-option
                  v-for="item in allSubAccount"
                  :key="item.id"
                  :label="item.phone"
                  :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="6">
          <el-form-item label="创建日期">
            <el-date-picker
                v-model="time"
                type="datetimerange"
                :picker-options="pickerOptions"
                size="mini"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="4" :xl="5">
          <el-form-item>
            <el-button type="primary"  @click="onSubmit" size="mini">搜索</el-button>
            <el-button class="reset" @click="onReset" size="mini">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="hint"><i class="el-icon-warning-outline"></i> 导入记录30天后会自动删除，避免占用服务器空间（不影响已导入的订单）</div>
  </div>
</template>

<script>
import {getASubAccount} from "@/api/login";

export default {
  props:{
    shopList:{
      type: Array,
      default:()=>[]
    }
  },
  name: "dateSearch",
  data: () => ({
    time:[] ,//时间
    shop:'', //店铺
    importAccount:'',//导入账号
    allSubAccount:[],//子账号
    pickerOptions:{  // 超过当前时间禁选
      disabledDate(time) {
        return time.getTime() > Date.now()
      }
    },

  }),
  mounted() {
    this.getASubAccount()
  },
  methods: {
    //获取子账号
    async getASubAccount(){
      try {
        const data = await getASubAccount()

        this.allSubAccount = data?.data

      }catch (e) {
        const {msg} = e
        this.$message.error(msg)
      }
    },
    //搜索
    onSubmit(){
      this.$emit('searchImportRecords',{shop:this.shop,time:this.time})
    },
    // 重置
    onReset(){
      this.shop = ''
      this.time = []
      this.onSubmit()
    }
  },
  watch: {},

}
</script>

<style scoped lang="less">
.creationDate {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin-right: 10px;
}
/deep/.el-date-editor {
  width: 260px;
}
.storeName {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin-bottom: 15px;
  .all {
    color: #FA4A14;
    margin: 0 10px;

  }
}
.hint {
  height: 30px;
  background: rgba(250, 74, 20, 0.1);
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
  //margin-top: 20px;
  margin-bottom: 10px;
  .el-icon-warning-outline {
    color: #FA4A14;;
    padding: 0 5px 0 20px;
    font-size: 16px;
    font-weight: 600;
  }
}

/deep/.el-form-item__content {
  flex: 1;
  width: 100%;
}
/deep/.el-input .el-input--mini {
  width: 100%;
}
/deep/.el-select--mini {
  width: 100%;
}
/deep/.el-input__inner {
  width: 100% !important;
}
/deep/.el-form-item {
  display: flex;
  width: 100%;
}
/deep/.el-form-item__label {
  width: auto !important;
}
</style>