import request from "@/utils/request";

const prefix = "/order";
//连接新店铺
export const shopsScreen = (callback) => {
  return request(
    `${prefix}/shopsScreen`,
    { params: {}, method: "get" },
    callback
  );
};
// 订单状态
export const orderStatus = (callback) => {
  return request(
    `${prefix}/orderStatus`,
    { params: {}, method: "get" },
    callback
  );
};
// 订单列表
// export const listOrder = (
//     {
//         perPage,
//         storeCode,
//         keywordOrCode,
//         startTime,
//         endTime,
//         status,
//         page,
//         shipStatus,
//         payStatus,
//         exceptionStatus,
//         userCode
//     },
//     callback
// ) => {
//     const data = {
//         perPage,
//         storeCode,
//         keywordOrCode,
//         startTime,
//         endTime,
//         status,
//         page,
//         shipStatus,
//         payStatus,
//         exceptionStatus,
//         userCode
//     }
//     return request(`${prefix}/listOrder`, {params: data, method: 'get'}, callback)
// }

export const listOrder = (
  { shipStatus, payStatus, status, endTime, page, startTime, orderNumber },
  callback
) => {
  const data = {
    shipStatus,
    payStatus,
    status,
    endTime,
    page,
    startTime,
    orderNumber,
  };
  return request("/order/list", { params: data, method: "get" }, callback);
};

// 获取收件地址详细
export const orderReceiveAddress = ({ orderId }, callback) => {
  const data = { orderId };
  return request(
    `${prefix}/orderReceiveAddress`,
    { params: data, method: "get" },
    callback
  );
};
// 获取订单详情
// export const orderInfo = (
//     {orderId},
//     callback
// ) => {
//     const data = {orderId}
//     return request(`${prefix}/orderInfo`, {params: data, method: 'get'}, callback)
// }

export const orderInfo = ({ orderNumber }, callback) => {
  const data = {
    orderNumber,
  };
  return request("/order/detail", { params: data, method: "get" }, callback);
};
//

// 修改订单地址
export const editOrderReceiveAddress = (
  { orderId, last_name, phone, zip, country, state, city, address1 },
  callback
) => {
  const data = {
    orderId,
    last_name,
    phone,
    zip,
    country,
    state,
    city,
    address1,
  };
  return request(
    `${prefix}/editOrderReceiveAddress`,
    { params: data, method: "put" },
    callback
  );
};
// 取消订单
// export const cancelOrder = (
//     {orderId},
//     callback
// ) => {
//     const data = {orderId}
//     return request(`${prefix}/cancelOrder`, {data, method: 'put'}, callback)
// }
export const cancelOrder = ({ orderNumber }, callback) => {
  const data = {
    orderNumber,
  };
  return request("/order/cancel", { data, method: "put" }, callback);
};

// 复制订单
// export const copyOrder = (
//     {orderId},
//     callback
// ) => {
//     const data = {orderId}
//     return request(`${prefix}/copyOrder`, {data, method: 'post'}, callback)
// }

export const copyOrder = ({ orderNumber }, callback) => {
  const data = {
    orderNumber,
  };
  return request("/order/copy", { data, method: "post" }, callback);
};
// 删除订单
// export const deleteOrder = (
//     {orderId},
//     callback
// ) => {
//     const data = {orderId}
//     return request(`${prefix}/deleteOrder`, {params: data, method: 'delete'}, callback)
// }
export const deleteOrder = ({ orderNumber }, callback) => {
  const data = {
    orderNumber,
  };
  return request("/order/delete", { data: data, method: "delete" }, callback);
};
//编辑订单商品
export const editOrderItem = (
  { orderId, orderItemId, code, amount },
  callback
) => {
  const data = {
    orderId,
    orderItemId,
    code,
    amount,
  };
  return request(`${prefix}/editOrderItem`, { data, method: "put" }, callback);
};
// 编辑商品获取同类商品
export const getOrderItemSkuList = ({ orderItemId }, callback) => {
  const data = { orderItemId };
  return request(
    `${prefix}/getOrderItemSkuList`,
    { params: data, method: "get" },
    callback
  );
};
// 删除订单商品
export const deleteOrderItem = ({ orderId, itemCode }, callback) => {
  const data = {
    orderId,
    itemCode,
  };
  return request(
    `${prefix}/deleteOrderItem`,
    { params: data, method: "delete" },
    callback
  );
};
// 支付宝支付
// export const orderPayByAlipay = (
//     {
//         orderNumbers
//     },
//     callback
// ) => {
//     const data = {
//         orderNumbers
//     }
//     return request(`${prefix}/orderPayByAlipay`, {data, method: 'post'}, callback)
// }
// // 余额支付
// export const orderPayByYue = (
//     {
//         orderNumbers
//     },
//     callback
// ) => {
//     const data = {
//         orderNumbers
//     }
//     return request(`${prefix}/orderPayByYue`, {data, method: 'post'}, callback)
// }

export const orderPayByYue = ({ orderNumbers, channel }, callback) => {
  const data = {
    orderNumbers,
    channel,
  };
  return request("/order/pay", { data, method: "post" }, callback);
};

//导入记录列表
export const importLogList = (params, callback) => {
  return request(
    `${prefix}/importLogList`,
    { params, method: "get" },
    callback
  );
};
//上传文件
export const importOrderFile = ({ storeCode, type, file }, callback) => {
  const formData = new FormData();
  formData.append("storeCode", storeCode);
  formData.append("type", type);
  formData.append("file", file);

  return request(
    `${prefix}/importOrderFile`,
    { data: formData, method: "post" },
    callback,
    true
  );
};

export const confirmOrder = ({ storeCode, type, file }, callback) => {
  const formData = new FormData();
  formData.append("storeCode", storeCode);
  formData.append("type", type);
  formData.append("file", file);

  return request(
    `${prefix}/confirmOrder`,
    { data: formData, method: "post" },
    callback,
    true
  );
};
//导出记录列表

export const exportShipLogList = (params, callback) => {
  return request(
    `${prefix}/exportShipLogList`,
    { params, method: "get" },
    callback
  );
};

// 请求报告
export const shipOrderExport = (data, callback) => {
  return request(
    `${prefix}/shipOrderExport`,
    { data, method: "post" },
    callback
  );
};

//导入FBA计划
export const importFBAPlan = ({ fbaPlanFile, storeId }, callback) => {
  const formData = new FormData();
  formData.append("fbaPlanFile", fbaPlanFile);
  formData.append("storeCode", storeId);
  return request(
    `${prefix}/importFBAPlan`,
    { data: formData, method: "post" },
    callback
  );
};

//FBA计划分页列表/order/fbaPlanPagination
export const fbaPlanPagination = (
  { page, perPage, status, storeCode, createdTimeRange, payTimeRange },
  callback
) => {
  const params = {
    page,
    perPage,
    status,
    storeCode: storeCode || undefined,
    createdTimeRange,
    payTimeRange,
  };
  return request(
    `${prefix}/fbaPlanPagination`,
    { params, method: "get" },
    callback
  );
};

//设计成品列表/order/fbaFinishedProductSelect
export const fbaFinishedProductSelect = (
  { page, perPage, nameOrCode, themeId },
  callback
) => {
  const params = {
    page,
    perPage,
    nameOrCode,
    themeId,
  };
  return request(
    `${prefix}/fbaFinishedProductSelect`,
    { params, method: "get" },
    callback
  );
};

//关联设计产品associatedDesignProducts
export const associatedDesignProducts = (
  { fbaItemId, designItemId },
  callback
) => {
  const data = {
    fbaItemId,
    designItemId,
  };
  return request(
    `${prefix}/associatedDesignProducts`,
    { data, method: "post" },
    callback
  );
};

//FBA计划分页列表fbaPlanPagination
export const fbaPlanStatusSelect = (callback) => {
  return request(`${prefix}/fbaPlanStatusSelect`, { method: "get" }, callback);
};

//确定FBA详情confirmEdit
export const confirmEdit = ({ fbaId }, callback) => {
  const data = {
    fbaId,
  };
  return request(`${prefix}/confirmEdit`, { data, method: "post" }, callback);
};

/**
 * 修改地址
 * @param fbaId fbaid
 * @param name 名称
 * @param country //国家
 * @param state //省
 * @param city //城市
 * @param line //地址
 * @param phone //电话
 * @param postcode //邮政
 * @param callback
 * @returns {*}
 */
export const alterAddress = (
  { fbaId, name, country, state, city, line, phone, postcode },
  callback
) => {
  const data = {
    fbaId,
    name,
    country,
    state,
    city,
    line,
    phone,
    postcode,
  };
  return request(`${prefix}/alterAddress`, { data, method: "post" }, callback);
};

//FBA订单支付-余额fbaPlanPayByYue
export const fbaPlanPayByYue = ({ fbaNumber, fbaStatus }, callback) => {
  let arr = [];
  !Array.isArray(fbaNumber) && !!fbaNumber
    ? arr.push(fbaNumber)
    : (arr = fbaNumber);
  const data = {
    fbaNumber: arr,
    fbaStatus,
  };
  return request(
    `${prefix}/fbaPlanPayByYue`,
    { data, method: "post" },
    callback
  );
};

//FBA订单支付-支付宝fbaPlanPayByAlipay
export const fbaPlanPayByAlipay = ({ fbaNumber, fbaStatus }, callback) => {
  let arr = [];
  !Array.isArray(fbaNumber) && !!fbaNumber
    ? arr.push(fbaNumber)
    : (arr = fbaNumber);
  const data = {
    fbaNumber: arr,
    fbaStatus,
  };
  return request(
    `${prefix}/fbaPlanPayByAlipay`,
    { data, method: "post" },
    callback
  );
};

//修改备注
export const repairNotes = ({ fbaNumber, remark }, callback) => {
  const data = {
    fbaNumber,
    remark,
  };
  return request(`${prefix}/repairNotes`, { data, method: "post" }, callback);
};
//上传盒子标签
export const uploadBoxLabel = ({ fbaNumber, boxLabelFileArr }, callback) => {
  const formData = new FormData();
  formData.append("fbaNumber", fbaNumber);

  for (let i = 0; i < boxLabelFileArr.length; i++) {
    for (let key in boxLabelFileArr[i]) {
      formData.append(`boxLabelFileArr[${i}][${key}]`, boxLabelFileArr[i][key]);
    }
  }
  return request(
    `${prefix}/uploadBoxLabel`,
    { data: formData, method: "post" },
    callback
  );
};

//取消FBA订单
export const cancelFBAPlan = ({ fbaNumber }, callback) => {
  const data = {
    fbaNumber,
  };
  return request(`${prefix}/cancelFBAPlan`, { data, method: "post" }, callback);
};

//获取FBA物流
export const getFBAShippingMethods = ({ factoryCode }, callback) => {
  const params = {
    factoryCode,
  };
  return request(
    `${prefix}/getFBAShippingMethods`,
    { params, method: "get" },
    callback
  );
};

//设置FBA物流方式
export const setFBAShippingMethods = ({ fbaNumber, channelCode }, callback) => {
  const data = {
    fbaNumber,
    channelCode,
  };
  return request(
    `${prefix}/setFBAShippingMethods`,
    { data, method: "put" },
    callback
  );
};

//获取文件标签
export const getBoxLabel = ({ fbaNumber }, callback) => {
  const params = {
    fbaNumber,
  };
  return request(`${prefix}/getBoxLabel`, { params, method: "get" }, callback);
};

//删除订单
export const deleteFbaPlan = ({ fbaNumber }, callback) => {
  const params = {
    fbaNumber,
  };
  return request(
    `${prefix}/deleteFbaPlan`,
    { params, method: "delete" },
    callback
  );
};

//支付状态

export const orderSpecialStatus = (callback) => {
  return request("/order/orderSpecialStatus", { method: "get" }, callback);
};

//修改收件人信息
export const updateAddress = (
  {
    fullName,
    phone,
    state,
    city,
    county,
    address,
    postal,
    addressCode,
    number,
    country,
  },
  callback
) => {
  const data = {
    fullName,
    country,
    phone,
    state,
    city,
    county:county || undefined,
    address,
    postal,
    addressCode,
    number
  };
  return request("/order/updateAddress", { data, method: "put" }, callback);
};
