<template>
  <div>
    <el-row class="el_row_title">
      <el-col :span="3" style="padding-left: 20px">导入编号</el-col>
      <el-col :span="4">导入时间</el-col>
      <el-col :span="3">导入格式</el-col>
      <el-col :span="3">导入店铺</el-col>
      <el-col :span="3">导入单数</el-col>
      <el-col :span="3">导入状态</el-col>
      <el-col :span="3">导入结果</el-col>
      <el-col :span="2">操作</el-col>
    </el-row>
    <el-row class="el_row_text" v-for="item in importRecordsList" :key="item.id">
      <el-col :span="3" class="indentation">{{item.code}}</el-col>
      <el-col :span="4">{{item.created_at}}</el-col>
      <el-col :span="3">{{item.extension}}</el-col>
      <el-col :span="3">{{item.store.name}}</el-col>
      <el-col :span="3">{{item.order_amount}}</el-col>
      <el-col :span="3">{{item.status_word}}</el-col>
      <el-col :span="3">成功导入 <span class="color">{{item.import_result_success}}</span> 个订单</el-col>
      <el-col :span="2" class="color pointer">下载</el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props:{
    importRecordsList:{
      type: Array,
      default:()=>[]
    }

  },
  name: "importRecordsList",
  data: () => ({}),
  mounted() {

  },
  methods: {},
  watch: {},

}
</script>

<style scoped lang="less">
.el_row_title {
  background: #F7F7F7;
  font-size: 15px;
  font-weight: 600;
  color: #333333;
  line-height: 40px;
}
.el_row_text {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  border-bottom: 1px solid #E9E9E9;
  padding: 15px 0 ;
  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  }
}
.color {
  color: #FA4A14;

}
.pointer {
  cursor: pointer;
}
.indentation {
  padding-left: 20px;
  word-break:break-all;
  letter-spacing: 0;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;  /*要显示的行数*/
  -webkit-box-orient: vertical;
}
</style>